import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const CarouselWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 2%;
  box-sizing: border-box;
  align-items: center;
  z-index: 999;
  pointer-events: none;
`

const Button = styled.div<{ $buttonHoverColor: string }>`
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #8e8e8e;
  transition: .3s;
  user-select: none;
  pointer-events: all;

  :hover {
    color: white;
    background-color: ${props => props.$buttonHoverColor};
  }
`

interface OverflowProps {
  $height: number;
}

const Overflow = styled.div<OverflowProps>`
  overflow: hidden;
  height: ${({ $height }) => $height + "px"};
`;

interface SlideContainerProps {
  $minHeight: number;
}

const SlideContainer = styled.div<SlideContainerProps>`
  min-height: ${({ $minHeight }) => $minHeight + "px"};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0 75px;
`;

const Slider = styled.div``;

export interface CarouselItem {
  component: JSX.Element;
}

interface CarouselProps {
  items: CarouselItem[];
  animationSeconds: number;
  buttonHoverColor?: string;
  autoSlideInterval?: number;
}

const Carousel = ({ items = [], animationSeconds = 1, buttonHoverColor = 'white', autoSlideInterval = 10000 }: CarouselProps) => {
  const [minHeight, setMinHeight] = useState<number>(0);
  const [filteredItems, setFilteredItems] = useState<CarouselItem[]>(items);

  const isAnimating = useRef<boolean>(false);
  const slideIndex = useRef<number>(0);
  const isHovered = useRef<boolean>(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const slideRef = useRef<HTMLDivElement>(null);
  const ticker = useRef<NodeJS.Timeout | null>(null);

  const transition = `${animationSeconds}s cubic-bezier(0.07, 0.13, 0.35, 0.94)`;

  const progressSlide = (inc: number) => {
    if (isAnimating.current) return;
    isAnimating.current = true;

    clearTimeout(ticker.current as NodeJS.Timeout);

    slideIndex.current =
      slideIndex.current + inc < 0
        ? items.length - 1
        : slideIndex.current + inc;

    const nextItem = items[slideIndex.current % items.length];

    setFilteredItems([...filteredItems, nextItem]);

    setTimeout(() => {
      if (slideRef.current) {
        slideRef.current.style.transition = "0s";
      }
      if (filteredItems.length > 2) {
        setFilteredItems([nextItem]);
      }

      // force cancel all iframes
      [].slice
        .call(slideRef?.current?.getElementsByTagName("iframe"))
        .forEach((iframe: HTMLIFrameElement) => {
          /* eslint-disable-next-line no-self-assign */
          iframe.src = iframe.src;
        });

      setTimeout(() => {
        if (slideRef.current) {
          slideRef.current.style.transition = transition;
        }
        isAnimating.current = false;
      }, 100);
    }, animationSeconds * 1000);
  };

  const beginTicker = () => {
    ticker.current = setTimeout(() => {
      if (buttonRef.current && !isHovered.current) {
        buttonRef.current.click();
      }
      clearTimeout(ticker.current as NodeJS.Timeout)
      beginTicker();
    }, autoSlideInterval);
  }

  useEffect(() => {
    let largestHeight = 0;
    if (slideRef.current) {
      const domItems: HTMLElement[] = [].slice.call(
        slideRef?.current?.getElementsByClassName("container")
      );
      domItems.forEach((item) => {
        if (item.clientHeight > largestHeight) {
          largestHeight = item.clientHeight;
        }
      });
      setMinHeight(largestHeight);
      slideRef.current.style.transition = transition;
    }

    setFilteredItems([items[0]]);

    beginTicker()
  }, [transition, items]);

  useEffect(() => {
    if (slideRef.current) {
      slideRef.current.style.transform = `translate3d(0, ${-(
        minHeight *
        (filteredItems.length - 1)
      )}px, 0)`;
    }
  }, [filteredItems, minHeight, transition]);

  const onHover = (isOver: boolean) => {
    clearTimeout(ticker.current as NodeJS.Timeout);
    beginTicker();
    isHovered.current = isOver;
  }

  return (
    <CarouselWrapper>
      <ButtonContainer>
        <Button $buttonHoverColor={buttonHoverColor} onClick={() => progressSlide(-1)}>{`<`}</Button>
        <Button ref={buttonRef} $buttonHoverColor={buttonHoverColor} onClick={() => progressSlide(1)}>{`>`}</Button>
      </ButtonContainer>
      <Overflow $height={minHeight} onMouseOver={() => onHover(true)} onMouseLeave={() => onHover(false)}>
        <Slider ref={slideRef}>
          {filteredItems.map(({ component }, index) => {
            return (
              <SlideContainer
                key={index}
                $minHeight={minHeight}
                className="container"
              >
                {component}
              </SlideContainer>
            );
          })}
        </Slider>
      </Overflow>
    </CarouselWrapper>
  );
};

export default Carousel;
